import { CompanyLinkageKind } from './company.domain';

export enum UserRole {
  None = 'None',
  Viewer = 'Viewer',
  Reviewer = 'Reviewer',
  ReviewDelegator = 'ReviewDelegator',
  Approver = 'Approver',
  ApproveDelegator = 'ApproveDelegator',
  Homologator = 'Homologator',
}

export enum UserKind {
  External = 'External',
  Internal = 'Internal',
}

export type InternalUserOrExternalUser = InternalUser | ExternalUser;

export interface CreateExternalUserDto {
  username?: string;
  password?: string;
  isActive: boolean;
  title?: string;
  name?: string;
  email?: string;
  phone?: string;
  mobile?: string;
  fax?: string;
  kind: UserKind;
  countryCode?: string;
  organizationID?: number;
  linkageKind: CompanyLinkageKind;
  numberOfShares?: number;
  percentageOfShares?: number;
}

export interface ExternalUser {
  id?: number;
  name?: string;
  username?: string;
  password?: string;
  isActive: boolean;
  title?: string;
  email?: string;
  phone?: string;
  mobile?: string;
  fax?: string;
  kind?: string;
  countryCode?: string;
  organizationID?: number;
  linkageKind: CompanyLinkageKind;
  numberOfShares?: number;
  percentageOfShares?: number;
}

export interface ExternalUserDto extends ExternalUser {}

export interface InternalUser {
  id?: number;
  name?: string;
  username?: string;
  password?: string;
  isActive: boolean;
  roleCode?: string;
  title?: string;
  email?: string;
  phone?: string;
  mobile?: string;
  fax?: string;
  kind?: string;
  departmentCode?: string;
  permissions: string[];
}

export interface InternalUserDto {
  id?: number;
  name?: string;
  username?: string;
  password?: string;
  isActive: boolean;
  title?: string;
  email?: string;
  phone?: string;
  mobile?: string;
  fax?: string;
  kind: UserKind;
  roleCode?: string;
  permissions?: string[];
  departmentCode: string;
  departmentName?: string;
}

export interface User {
  id?: number;
  insertDateTime: string;
  lastUpdateDateTime: string;
  insertedByUser: User;
  insertedByUserID: number;
  lastUpdatedByUser: User;
  lastUpdatedByUserID: number;
  kind: UserKind;
  username?: string;
  passwordHash?: string;
  title?: string;
  name?: string;
  email?: string;
  emailVerified: boolean;
  mobile?: string;
  phone?: string;
  fax?: string;
  lastLogin: string;
}

export interface UserLoginDto {
  id?: number;
  kind: UserKind;
  username?: string;
  password?: string;
  isActive: boolean;
  title?: string;
  name?: string;
  email?: string;
  phone?: string;
  mobile?: string;
  fax?: string;
  lastLogin: string;
  countryCode?: string;
  organizationID?: number;
  linkageKind: CompanyLinkageKind;
  numberOfShares?: number;
  percentageOfShares?: number;
  roleCode?: string;
  permissions?: string[];
  departmentCode?: string;
}

export interface UserWithToken {
  user: UserLoginDto;
  accessToken: string;
}

export enum UserPermissions {
  None = 'None',
  CanLogin = 'CanLogin',
  CanViewOwnAccount = 'CanViewOwnAccount',
  CanRecoverOwnAccount = 'CanRecoverOwnAccount',
  CanUpdateOwnAccount = 'CanUpdateOwnAccount',
  CanManageOwnAccount = 'CanManageOwnAccount',
  CanManageAnnouncement = 'CanManageAnnouncement',
  CanViewGenerics = 'CanViewGenerics',
  CanCreateGenerics = 'CanCreateGenerics',
  CanUpdateGenerics = 'CanUpdateGenerics',
  CanDeleteGenerics = 'CanDeleteGenerics',
  CanManageGenerics = 'CanManageGenerics',
  CanViewOwnOrganization = 'CanViewOwnOrganization',
  CanCreateOwnOrganization = 'CanCreateOwnOrganization',
  CanUpdateOwnOrganization = 'CanUpdateOwnOrganization',
  CanDeleteOwnOrganization = 'CanDeleteOwnOrganization',
  CanManageOwnOrganization = 'CanManageOwnOrganization',
  CanViewAnyOrganization = 'CanViewAnyOrganization',
  CanCreateAnyOrganization = 'CanCreateAnyOrganization',
  CanUpdateAnyOrganization = 'CanUpdateAnyOrganization',
  CanDeleteAnyOrganization = 'CanDeleteAnyOrganization',
  CanManageAnyOrganization = 'CanManageAnyOrganization',
  CanViewExternalUsers = 'CanViewExternalUsers',
  CanViewAllExternalUsers = 'CanViewAllExternalUsers',
  CanCreateExternalUsers = 'CanCreateExternalUsers',
  CanRecoverExternalUserAccounts = 'CanRecoverExternalUserAccounts',
  CanUpdateExternalUsers = 'CanUpdateExternalUsers',
  CanDeleteExternalUsers = 'CanDeleteExternalUsers',
  CanManageExternalUsers = 'CanManageExternalUsers',
  CanViewInternalUsers = 'CanViewInternalUsers',
  CanViewAllInternalUsers = 'CanViewAllInternalUsers',
  CanCreateInternalUsers = 'CanCreateInternalUsers',
  CanRecoverInternalUserAccounts = 'CanRecoverInternalUserAccounts',
  CanUpdateInternalUsers = 'CanUpdateInternalUsers',
  CanDeleteInternalUsers = 'CanDeleteInternalUsers',
  CanManageInternalUsers = 'CanManageInternalUsers',
  CanViewOwnMessage = 'CanViewOwnMessage',
  CanCreateOwnMessage = 'CanCreateOwnMessage',
  CanUpdateOwnMessage = 'CanUpdateOwnMessage',
  CanDeleteOwnMessage = 'CanDeleteOwnMessage',
  CanManageOwnMessage = 'CanManageOwnMessage',
  CanViewAnyMessage = 'CanViewAnyMessage',
  CanDeleteAnyMessage = 'CanDeleteAnyMessage',
  CanManageAnyMessage = 'CanManageAnyMessage',
  CanViewProcess = 'CanViewProcess',
  CanCreateProcess = 'CanCreateProcess',
  CanUpdateProcess = 'CanUpdateProcess',
  CanDeleteProcess = 'CanDeleteProcess',
  CanManageProcess = 'CanManageProcess',
  CanViewAnyProcess = 'CanViewAnyProcess',
  CanUpdateAnyProcess = 'CanUpdateAnyProcess',
  CanDeleteAnyProcess = 'CanDeleteAnyProcess',
  CanManageAnyProcess = 'CanManageAnyProcess',
  CanReviewProcess = 'CanReviewProcess',
  CanReviewAnyProcess = 'CanReviewAnyProcess',
  CanApproveAnyProcess = 'CanApproveAnyProcess',
  CanAssignAnyProcess = 'CanAssignAnyProcess',
  CanHomologateAnyProcess = 'CanHomologateAnyProcess',
  _County_ = '_County_',
  _Launcher_ = '_Launcher_',
  _System_ = '_System_',
}
